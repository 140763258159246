<template>
  <router-view />
</template>

<style>
@import './assets/css/tailwind.css';
@import 'https://reinhart1010.github.io/aileron/aileron.min.css';

#app {
  font-family: 'aileron';
  font-weight: 200;
}
</style>
